import React, { createContext, useState } from "react";

// On crée le context
export const Mycontext = createContext();

// On crée le provider qui utilise un hook pour gérer le state
export const MycontextProvider = ({ children }) => {
    const [datas, setDatas] = useState([]);
    const [menuLeft, setMenuLeft] = useState([]);
    const [contextItem, setContextItem] = useState(null);
    const [currentSubItem, setCurrentSubItem] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [globalItem, setGlobalItem] = useState(null);
    const [back , setBack] = useState("");
    const [contextMediaArray, setContextMediaArray] = useState([]);
    const [currentDoctor, setCurrentDoctor] = useState(null);

    // const [route, setRoute] = useState(null);
    return (
        <Mycontext.Provider value={{ datas, setDatas, menuLeft, setMenuLeft, contextItem, setContextItem, currentItem, setCurrentItem, currentSubItem, setCurrentSubItem, globalItem, setGlobalItem, back, setBack, contextMediaArray, setContextMediaArray, currentDoctor, setCurrentDoctor }}>
            {children}
        </Mycontext.Provider>
    );
};