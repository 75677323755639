import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

export default function MenuBar({ navigation }){
    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => navigation.navigate('body')} >
                <Ionicons name="home" size={32} color="white" style={styles.homeIcon} />
            </TouchableOpacity>
        </View>
    )
}
export const styles = StyleSheet.create({
    container: {
        height: 60,
        width:60
    },
    homeIcon: {
        textAlign: 'center',
        padding: 10
    }
})
