import React, { useContext } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, FlatList, Dimensions, SectionList } from 'react-native';
import Constants from 'expo-constants';
import { Ionicons } from '@expo/vector-icons';
import { Mycontext } from "../context/Context";

//
import { SafeAreaView, } from 'react-native-safe-area-context';
//


export default function RappelAnatomique({ navigation }) {

    const { datas, setCurrentItem, setBack } = useContext(Mycontext);

    const dimensions = Dimensions.get('window');
    const ratio = dimensions.width / 1920;
    const finalWidth = Math.round(dimensions.width / 3.5);

    //

    const navigate = (route, item, backRoad) => {
        setCurrentItem(item);
        setBack(backRoad);
        navigation.navigate(route)
    } 

    const renderSection = ({ item }) => {
        return (
            <FlatList
                data={item.list}
                numColumns={3}
                renderItem={renderListItem}
                keyExtractor={keyExtractor}
            />
        )
    }

    const renderSectionHeader = ({ section }) => {
        return <Text style={styles.title}>{section.titre}</Text>


    }

    const renderListItem = ({ item }) => {
        return (
            <View style={styles.blocMosaic}>
                <TouchableOpacity onPress={() => navigate("video", item, "rappel")} >
                    <Image style={[styles.image]} source={{ uri: item.posterUri }} imageStyle={{ resizeMode: 'contains' }}>
                    </Image>
                    <Text style={styles.txtTitle}>{item.name}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    const keyExtractor = (item) => {
        return item.name
    }

    const styles = StyleSheet.create({
        blocMosaic: {
            width: 1920 * ratio,
            height: 1080 * ratio,
            backgroundColor: "#0F0F0F",
            overflow: 'hidden'
        },
        image: {
            width: (1920 * ratio) - 10,
            height: 1080 * ratio,
            margin: 5
        },
        colonne2: {
            flex: 1,
            marginTop: 24,
            alignItems: 'center'


        },
        container: {
            flex: 1,
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            backgroundColor: '#000000',
            flexDirection: "column",
            paddingTop: Constants.statusBarHeight + 5,
        },
        mainLogo: {
            width: 1920 / 30,
            height: 1920 / 30,
            position: 'absolute',
            bottom: 10,
            right: 0
        },
        homeBt: {
            position: 'absolute',
            top: Constants.statusBarHeight + 8,
            right: 10
        },
        btHead: {
            height: 60,
            width: 60,
            marginLeft: 20
        },
        txtWhite: {
            color: '#ffffff',
            fontSize: 20,
            paddingTop: 8,
            paddingLeft: 20,
            paddingBottom: 8
        },
        txtTitle: {
            color: "#ffffff",
            fontSize: 12
        },
        header: {
            fontSize: 20,
            backgroundColor: "#fff"
        },
        title: {
            fontSize: 16,
            color: "#000000",
            backgroundColor: '#DCDCDC',
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10
        },
        blocMosaic: {
            width: finalWidth,
            height: (finalWidth * 1080 / 1920) + 30,
            // backgroundColor: "#ff0000",
            margin: 5,
        },
        image: {
            width: finalWidth - 10,
            height: (finalWidth * 1080 / 1920)
        },
        txtTitle: {
            color: "#ffffff",
            fontSize: 14,
        }
    })

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.colonne2}>
                <SectionList
                    sections={datas.rappelAnatomiques[0].rappelAnatomiqueCategories}
                    keyExtractor={(item, index) => item + index}
                    renderSectionHeader={renderSectionHeader}
                    renderItem={renderSection}
                />
            </View>
            <TouchableOpacity style={styles.homeBt} onPress={() => navigate('body', null)}>
                <Ionicons name="home" size={32} color="white" style={styles.homeIcon} />
            </TouchableOpacity>
            <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
        </SafeAreaView>
    )

}

