import React, { useContext, useEffect } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, Image, Dimensions } from 'react-native';
import Constants from 'expo-constants';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Mycontext } from "../context/Context";

// https://github.com/react-native-video/react-native-video/issues/746

const dimensions = Dimensions.get('window');
const finalWidth = Math.round(dimensions.width / 3.5);

export default function MediaList({ navigation}){
    // CONSTANT

    const { currentDoctor, setCurrentItem, back, setBack, contextMediaArray } = useContext(Mycontext);

    useEffect(() => {
        setBack("medias-list");
    }, []);
    const title = contextMediaArray[0].categoryType.name;
    const navigateTo = (route, item, backRoad) => {
        setCurrentItem(item);
        setBack(backRoad);
        navigation.navigate(route)
    }



    return (
        <SafeAreaView style={styles.container}>
            <View>

                <Text style={styles.txtWhite}>{title} : {currentDoctor.grade} {currentDoctor.nom} {currentDoctor.prenom}</Text>
            </View>
            <View style={styles.colonne2}>
                <FlatList data={contextMediaArray} keyExtractor={(item, index) => 'key' + index} numColumns={3} renderItem={({ item }) => {
                    if (item.mediaType != "picture") {
                        return (<View style={styles.blocMosaic}>
                            <TouchableOpacity onPress={() => navigateTo("video", item, back)} >
                                <Image style={[styles.image]} source={{ uri: item.posterUri }} imageStyle={{ resizeMode: 'contain' }}>
                                </Image>
                                <Text style={styles.txtTitle}>{item.name}</Text>
                            </TouchableOpacity>
                        </View>
                        )
                    } else {
                        return (<View style={styles.blocMosaic}>
                            <TouchableOpacity onPress={() => navigateTo("video", item, back)} >
                                <Image style={[styles.image]} source={{ uri: item.mediaUri }} imageStyle={{ resizeMode: 'contain' }}>
                                </Image>
                                <Text style={styles.txtTitle}>{item.name}</Text>
                            </TouchableOpacity>
                        </View>
                        )
                    }
                }} />
            </View>
            <TouchableOpacity style={styles.backBt} onPress={() => navigation.navigate('docteurs')}>
                <Ionicons name="caret-back" size={32} color="white" style={styles.homeIcon} />
            </TouchableOpacity>
            <TouchableOpacity style={styles.homeBt} onPress={() => navigateTo('body')}>
                <Ionicons name="home" size={32} color="white" style={styles.homeIcon} />
            </TouchableOpacity>
            <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: '#000000',
        flexDirection: "column",
        // paddingTop: Constants.statusBarHeight + 5,
    },
    txtWhite: {
        color: '#ffffff',
        fontSize: 20,
        paddingTop: 8,
        paddingLeft: 20,
        paddingBottom: 8,
        textAlign: 'center'
    },
    mainLogo: {
        width: 1920 / 30,
        height: 1920 / 30,
        position: 'absolute',
        bottom: 10,
        right: 0
    },
    homeBt: {
        position: 'absolute',
        top: Constants.statusBarHeight + 8,
        right: 10
    },
    backBt: {
        position: 'absolute',
        top: Constants.statusBarHeight + 8,
        left: 10
    },
    colonne2: {
        flex: 1,
        alignItems: 'center',

    },
    blocMosaic: {
        width: finalWidth,
        height: (finalWidth * 1080 / 1920) + 30,
        // backgroundColor: "#ff0000",
        margin: 5,
    },
    image: {
        width: finalWidth,
        height: (finalWidth * 1080 / 1920)
    },
    txtTitle: {
        color: "#ffffff",
        fontSize: 14,
        paddingTop: 5
    }
});

