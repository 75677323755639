import React, { useContext } from 'react';
import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { Video } from 'expo-av';
import Constants from 'expo-constants';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView, } from 'react-native-safe-area-context';
import { Mycontext } from "../context/Context";

export default function ScreenVideo({ navigation }) {
    const {contextItem, back, contextMediaArray } = useContext(Mycontext);
    //
    const video = React.createRef();
    //



    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: '#000000',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'stretch'
        },
        video: {
            width: '100%',
            height: '100%'
        },
        buttons: {
            backgroundColor: '#ffffff',
            color: '#000000',
            position: 'absolute',
            bottom: 8
        },
        mainLogo: {
            width: 1920 / 30,
            height: 1920 / 30,
            position: 'absolute',
            bottom: 10,
            right: 0
        },
        homeBt: {
            position: 'absolute',
            top: Constants.statusBarHeight + 8,
            right: 10
        },
        backBt: {
            position: 'absolute',
            top: Constants.statusBarHeight + 8,
            left: 10
        },
        image: {
            width: '100%',
            height: '100%'
        }
    });

    if (contextItem.mediaType != "picture") {
        return (
            <SafeAreaView style={styles.container}>
                <Video
                    ref={video}
                    style={styles.video}
                    source={{
                        uri: contextMediaArray[0].mediaUri,
                    }}
                    useNativeControls
                    shouldPlay={true}
                    volume={1.0}
                    isMuted={false}
                    resizeMode="contain"
                    isLooping
                />
                <TouchableOpacity style={styles.backBt} onPress={() => navigation.navigate(back)}>
                    <Ionicons name="caret-back" size={32} color="white" style={styles.homeIcon} />
                </TouchableOpacity>
                <TouchableOpacity style={styles.homeBt} onPress={() => navigation.navigate('body')}>
                    <Ionicons name="home" size={32} color="white" style={styles.homeIcon} />
                </TouchableOpacity>
                <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
            </SafeAreaView>
        )
    } else {
        return (
            <SafeAreaView style={styles.container}>
                <Image resizeMode="contain" source={{ uri: item.mediaUri }} style={[styles.image]}></Image>
                <TouchableOpacity style={styles.backBt} onPress={() => navigation.navigate(back)}>
                    <Ionicons name="caret-back" size={32} color="white" style={styles.homeIcon} />
                </TouchableOpacity>
                <TouchableOpacity style={styles.homeBt} onPress={() => navigation.navigate('body')}>
                    <Ionicons name="home" size={32} color="white" style={styles.homeIcon} />
                </TouchableOpacity>
                <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
            </SafeAreaView>
        )
    }

    

}




//
// export default ScreenPresentationDoctor;