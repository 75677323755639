import React from 'react';
import { StyleSheet,  Image, ImageBackground, TouchableOpacity, Text, Dimensions, SafeAreaView } from 'react-native';
// import { SafeAreaView } from 'react-native-safe-area-context';
import Constants from 'expo-constants';
//
const dimensions = Dimensions.get('window');
const originalHeight = 1920;
const originalWidth = 1080;
const originalHeadHeight = 375;
const originalChestHeight = 446;
const originalLegsHeight = 1099;
//
let finalScreenHeight = dimensions.height - (Constants.statusBarHeight + 5);
if(dimensions.height > dimensions.width){
    finalScreenHeight = dimensions.width - (Constants.statusBarHeight + 5);
}
const ratio = finalScreenHeight / originalHeight;
const finalWidth = originalWidth * ratio;
//
export default function ScreenBody({navigation}){

    return (
        <SafeAreaView style={styles.container}>
        <ImageBackground source={require('../../assets/model-1920.png')} style={styles.containerBody}>
            <TouchableOpacity onPress={() => navigation.navigate('tete')} style={styles.headButton}>
                <Text style={styles.textWhite}>Visage et cou</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('tronc')} style={styles.chestButton}>
                <Text style={styles.textWhite}>Haut du corps</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('jambes')} style={styles.legsButton}>
                <Text style={styles.textWhite}>Bas du corps</Text>
            </TouchableOpacity>
        </ImageBackground>
        <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
        </SafeAreaView>
    )
} 
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#000000',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerBody: {
        // flex:1
    },
    headButton: {
        width: finalWidth,
        height: originalHeadHeight * ratio,
        justifyContent: 'center',
        alignItems: 'flex-end',
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
    },
    chestButton: {
        width: finalWidth,
        height: originalChestHeight * ratio,
        justifyContent: 'center',
        alignItems: 'flex-end',
        borderBottomColor: '#cccccc',
        borderBottomWidth: 1,
    },
    legsButton: {
        width: finalWidth,
        height: originalLegsHeight * ratio,
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    mainLogo: {
        width: 1920 / 30,
        height: 1920 / 30,
        position: 'absolute',
        bottom: 10,
        right: 0
    },
    textWhite:{
        color:'white',
        fontSize:12,
        left: finalWidth - 50,
        position: 'absolute'
    }
});