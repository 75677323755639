import * as React from 'react';
import MainStackNavigator from './src/navigation/MainStackNavigator';
import { MycontextProvider } from "./src/context/Context";

export default class App extends React.Component {
  render() {
    return (
      <MycontextProvider>
        <MainStackNavigator />
      </MycontextProvider>
    );
  }
}
