import React, { useEffect, useContext, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, SafeAreaView, Dimensions, Input } from 'react-native';
import axios from "axios";
import { Mycontext } from "../context/Context";
import { TextInput } from "react-native-gesture-handler";
import { Ionicons } from '@expo/vector-icons';

//
const dimensions = Dimensions.get('window');
let imageSize = dimensions.height * 0.35;
if(dimensions.height > dimensions.width){
    imageSize = dimensions.width * 0.35;
}
//
const LoadScreen = ({ navigation }) => {
    const [isConnected, setIsConnected] = React.useState(false);
    const { setDatas } = useContext(Mycontext);
    const [ welcomeMessage , setWelcomeMessage] = React.useState("Chargement en cours ...");
    const [ mdp , setMdp] = React.useState("");
    const [ errorMessage , setErrorMessage] = React.useState("");
    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const [eyeIcon, setEyeIcon] = React.useState("eye-outline");
    // Axios
    useEffect(() => {
        axios.get('https://croma.cmeneux.fr/api/bodies')
        .then(response => {
            setDatas(response.data['hydra:member'][0]);
            setWelcomeMessage("Entrer");
            setIsConnected(true);
        })
        .catch(error => {
            setIsConnected(false);
        });
    }, []);
    //

    const handleMdpChange = (mdp) => {
        setMdp(mdp);
    }

    const handleConnection =() => {
        // console.log(mdp);
        axios.get("https://croma.cmeneux.fr/login/app?password="+mdp)
            .then((response) => {
                if(response.data == "ok"){
                    navigation.navigate('body');
                    setErrorMessage("");
                }else{
                    setErrorMessage("Mot de passe incorrect");
                }
            });
        
    }

    const handleSecureTextEntryChange = () => {
        if(secureTextEntry){
            setSecureTextEntry(false);
            setEyeIcon("eye-off-outline");
        }else{
            setSecureTextEntry(true);
            setEyeIcon("eye-outline");
        }
    }

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.container}>
                <View style={styles.block}>
                    <Image source={require('../../assets/adaptive-icon.png')} resizeMode='cover' style={styles.logo}></Image>
                            {
                                isConnected 
                                ?
                                (
                                <View>
                                    <View style={styles.inputView}>
                                        <TextInput style={styles.mdpInput} secureTextEntry={secureTextEntry} placeholder="Mot de passe" onChangeText={(text) => handleMdpChange(text)} value={mdp} />
                                        <TouchableOpacity onPress={() => {handleSecureTextEntryChange()}}>
                                            <Ionicons name={eyeIcon} size={26} color="black" style={styles.eyeIcon} />
                                        </TouchableOpacity>
                                    </View>
                                <TouchableOpacity onPress={() => handleConnection()} style={styles.button}>
                                    <Text style={styles.buttonText}>{welcomeMessage}</Text> 
                                </TouchableOpacity>
                                <Text style={styles.wrongPassword}>{errorMessage}</Text>
                                </View>
                                ) 
                                :
                                <Text style={styles.textNotConnected}>Merci de vous connecter à internet</Text>
                            }
                </View>
            </View>
        </SafeAreaView>
    )
}
// STYLES
const styles = StyleSheet.create({
    textNotConnected:{
        color: 'black',
        marginTop: 10,
        textAlign: 'center',
    },
    container: {
        flex: 1,
        backgroundColor: '#ffffff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    block: {
        width: imageSize,
        height: imageSize + 60,
        backgroundColor: '#ffffff',
    },
    logo: {
        width: imageSize,
        height: imageSize
    },
    button: {
        backgroundColor: '#000000',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 9999,
        width: imageSize - 20,
        marginLeft: 10,
        marginTop: 10
    },
    buttonText: {
        color: '#ffffff',
        textAlign: 'center',
    },
    wrongPassword: {
        color: 'red',
        textAlign: 'center',
    },
    mdpInput : {
        textAlign: 'center',
        borderColor: 'black',
        borderWidth: 1,
        borderRadius: 5,
        minWidth: imageSize - 20,
    },
    inputView: {
        marginTop: 10,
        marginLeft: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    eyeIcon: {
        marginLeft: 10
    }
});

export default LoadScreen;
