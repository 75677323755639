import React, {useState, useContext, useEffect} from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, Image  } from 'react-native';
import Constants from 'expo-constants';
import { Ionicons } from '@expo/vector-icons';
import { Mycontext } from "../context/Context";
//
import { SafeAreaView, } from 'react-native-safe-area-context';
let analyseArray;
let tracesArray;
let anesthesieArray;
let traitementArray;
let photosArray
let presentationArray;
//
export default function DocteurScreen({navigation}){
    const { contextItem,  setGlobalItem, setBack, setContextMediaArray, currentDoctor, setCurrentDoctor } = useContext(Mycontext);
    //
    const [pressedItem, setPressedItem] = useState(null);
    const [middleLeftVisibility, setMiddleLeftVisibility] = useState(0);
    const [middleLeftCompVisibility, setMiddleLeftCompVisibility] = useState(0);
    const [menuLeftIndex, setMenuLeftIndex] = useState(0);
    const [opac1, setOpac1] = useState(0.5);
    const [opac2, setOpac2] = useState(0.5);
    const [opac3, setOpac3] = useState(0.5);
    const [opac4, setOpac4] = useState(0.5);
    const [opac5, setOpac5] = useState(0.5);
    const [opac6, setOpac6] = useState(0.5);
    const [arrowDisplay, setArrowDisplay] = useState(0);
    const [subOpac1, setSubOpac1] = useState(0.5);
    const [subOpac2, setSubOpac2] = useState(0.5);
    const [subOpac3, setSubOpac3] = useState(0.5);
    const [analyseDisplay, setAnalyseDisplay] = useState('none');
    const [tracesDisplay, setTracesDisplay] = useState('none');
    const [anesthesieDisplay, setAnesthesieDisplay] = useState('none');
    const [traitementDisplay, setTraitementDisplay] = useState('none');
    const [photosDisplay, setPhotosDisplay] = useState('none');
    const [presentationDisplay, setPresentationDisplay] = useState('none');
    const [subDisplay1, setSubDisplay1] = useState('none');
    const [subDisplay2, setSubDisplay2] = useState('none');
    const [subDisplay3, setSubDisplay3] = useState('none');
    const [tag, setTag] = useState(null);
    const [subtag, setSubtag] = useState(null);

    useEffect(() => {
        setGlobalItem(null);
    }, []);



    const navigate2 = (item) => {
        let medias = currentDoctor.movies;
        let _tag = tag;
        let _subTag = subtag;
        let mediasArray = medias.filter(function (movie) {
            return movie.categoryType.tag == _tag;
        });
        let finalMediaArray = mediasArray.filter(function (media) {
            return media.apiZone.route == item.route
        });


        // Présentation ou autre
        if (_tag == "presentation") {
            setContextMediaArray(mediasArray);
            setBack("docteurs");

            navigation.navigate("presentation");
        } else {
            if (_tag == "traitement" && subtag != null) {
                let mediaList = finalMediaArray.filter(function (movie) {
                    return movie.mediaSubType == _subTag
                })
                finalMediaArray = [...mediaList];


            }

            setGlobalItem(item);
            setContextMediaArray(finalMediaArray);

            navigation.navigate("medias-list");
        }
    }

    const changeSelected = (index, item) => {
        // Reset des tableaux
        analyseArray = [];
        tracesArray = [];
        anesthesieArray = [];
        traitementArray = [];
        photosArray = [];
        presentationArray = [];
        // Mise à jour des states
        setAnalyseDisplay("none");
        setTracesDisplay("none" );
        setAnesthesieDisplay("none");
        setTraitementDisplay("none");
        setPhotosDisplay("none");
        setPresentationDisplay("none");
        setSubDisplay1("none");
        setSubDisplay2("none");
        //
        setOpac1(0.5);
        setOpac2(0.5);
        setOpac3(0.5);
        setOpac4(0.5);
        setOpac5(0.5);
        setOpac6(0.5);
        setArrowDisplay(0);
        //
        setPressedItem(index)
        setCurrentDoctor(item)
        setMiddleLeftVisibility(1)
        // Récupération des movies de la route demandée (cuir-chevelu par exemple)
        let routeToReach = contextItem.route;
        var result = item.movies.filter(function (movie) {
            return movie.apiZone.route == routeToReach
        });

        //
        presentationArray = item.movies.filter(function (item) {
            return item.categoryType.tag == "presentation";
        });
        if (presentationArray.length > 0) setPresentationDisplay("flex")
        // Passage en revue des différents types
        // Analyse 
        analyseArray = result.filter(function (item) {
            return item.categoryType.tag == "analyse";
        });
        if (analyseArray.length > 0) setAnalyseDisplay("flex")
        // Tracès
        tracesArray = result.filter(function (item) {
            return item.categoryType.tag == "traces";
        });
        if (tracesArray.length > 0) setTracesDisplay("flex")
        // Anesthesie
        anesthesieArray = result.filter(function (item) {
            return item.categoryType.tag == "anesthesie";
        });
        if (anesthesieArray.length > 0) setAnesthesieDisplay("flex")
        // Traitement
        traitementArray = result.filter(function (item) {
            return item.categoryType.tag == "traitement";
        });
        if (traitementArray.length > 0) setTraitementDisplay("flex")
        // Photo
        photosArray = result.filter(function (item) {
            return item.categoryType.tag == "photos";
        });
        if (photosArray.length > 0) setPhotosDisplay("flex")
    }

    const leftChoice = (index, item) => {
        setSubDisplay1("none");
        setSubDisplay2("none");
        setSubDisplay3("none");
        setSubtag(null);
        switch (index) {
            case 1:
                setTag("analyse")
                setOpac1(1);
                setOpac2(0.5);
                setOpac3(0.5);
                setOpac4(0.5);
                setOpac5(0.5);
                setOpac6(0.5);
                setMiddleLeftCompVisibility(0);
                setArrowDisplay(1);
                break;
            case 2:
                setTag("traces")
                setOpac1(0.5);
                setOpac2(1);
                setOpac3(0.5);
                setOpac4(0.5);
                setOpac5(0.5);
                setOpac6(0.5);
                setMiddleLeftCompVisibility(0);
                setArrowDisplay(1);
                break;
            case 3:
                setTag("anesthesie")
                setOpac1(0.5);
                setOpac2(0.5);
                setOpac3(1);
                setOpac4(0.5);
                setOpac5(0.5);
                setOpac6(0.5);
                setMiddleLeftCompVisibility(0);
                setArrowDisplay(1);
                break;
            case 4:
                console.log("Traitement");
                setTag("traitement")
                setOpac1(0.5);
                setOpac2(0.5);
                setOpac3(0.5);
                setOpac4(1);
                setOpac5(0.5);
                setOpac6(0.5);
                setMiddleLeftCompVisibility(0);
                setArrowDisplay(0);
                let route = contextItem.route;
                if (route == "cuir-chevelu") {
                    setArrowDisplay(1);
                } else {
                    setSubtag(null);
                    // Analyse des types de traitement
                    let acide = traitementArray.filter(function (movie) {
                        return movie.mediaSubType == "acide";
                    });
                    let fil = traitementArray.filter(function (movie) {
                        return movie.mediaSubType == "fil";
                    });
                    let peau = traitementArray.filter(function (movie) {
                        return movie.mediaSubType == "peau";
                    });
                    console.log(acide.length);
                    console.log(fil.length);
                    console.log(peau.length);
                    //
                    if (acide.length > 0) {
                        setSubOpac1(0.5);
                        setSubDisplay1("flex");
                        setMiddleLeftCompVisibility(1);
                    }
                    if (fil.length > 0) {
                        setSubOpac2(0.5);
                        setSubDisplay2("flex");
                        setMiddleLeftCompVisibility(1);
                    }
                    if (peau.length > 0) {
                        setSubOpac3(0.5);
                        setSubDisplay3("flex");
                        setMiddleLeftCompVisibility(1);
                    }
                }
                break;
            case 5:
                setTag("photos")
                setOpac1(0.5);
                setOpac2(0.5);
                setOpac3(0.5);
                setOpac4(0.5);
                setOpac5(1);
                setOpac6(0.5);
                setMiddleLeftCompVisibility(0);
                setArrowDisplay(1);
                break;
            case 6:
                setTag("presentation")
                setOpac1(0.5);
                setOpac2(0.5);
                setOpac3(0.5);
                setOpac4(0.5);
                setOpac5(0.5);
                setOpac6(1);
                setMiddleLeftCompVisibility(0);
                setArrowDisplay(1);
                break;
            default:
                setOpac1(0.5);
                setOpac2(0.5);
                setOpac3(0.5);
                setOpac4(0.5);
                setArrowDisplay(0);
        }
        setMenuLeftIndex(index);
    }

    const subLeftChoice = (index, item) => {
        console.log("subLeftChoice");
        setSubtag(null);
        setSubOpac1(0.5);
        setSubOpac2(0.5);
        switch (index) {
            case 1:
                setSubtag("acide");
                setSubOpac1(1);
                setSubOpac2(0.5);
                setSubOpac3(0.5);
                setArrowDisplay(1);
                break;
            case 2:
                setSubtag("fil");
                setSubOpac1(0.5);
                setSubOpac2(1);
                setSubOpac3(0.5);
                setArrowDisplay(1);
                break;
            case 3:
                setSubtag("peau");
                setSubOpac1(0.5);
                setSubOpac2(0.5);
                setSubOpac3(1);
                setArrowDisplay(1);
                break;
            default:
                setSubOpac1(0.5);
                setSubOpac2(0.5);
                setArrowDisplay(0);
        }
    }


   

    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.colLeft}>
                <FlatList data={contextItem.doctors} keyExtractor={(item, index) => 'key' + index} renderItem={({ item, index }) => {
                    if (index === pressedItem) {
                        return (
                            <TouchableOpacity item={item} onPress={() => changeSelected(index, item)}>
                                <Text style={[styles.btGauche, { opacity: 0.8 }]}>{item.grade} {item.nom} {item.prenom}</Text>
                            </TouchableOpacity>
                        );
                    } else {
                        return (
                            <TouchableOpacity item={item} onPress={() => changeSelected(index, item)}>
                                <Text style={[styles.btGauche, { opacity: 0.5 }]}>{item.grade} {item.nom} {item.prenom}</Text>
                            </TouchableOpacity>
                        );
                    }
                }} />
            </View>
            <View style={styles.colRight}>
                <Text style={styles.titre}>{contextItem.titre}</Text>
                <View style={styles.ligneMiddle}>
                    <View style={styles.ligneMiddleLeft}>
                        <View style={{ opacity: middleLeftVisibility, display: analyseDisplay }}>
                            <TouchableOpacity onPress={() => leftChoice(1, contextItem)}>
                                <Text style={[styles.boutonMiddleLeft, { opacity: opac1 }]}>Analyse du visage</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ opacity: middleLeftVisibility, display: tracesDisplay }}>
                            <TouchableOpacity onPress={() => leftChoice(2, contextItem)}>
                                <Text style={[styles.boutonMiddleLeft, { opacity: opac2 }]}>Tracés</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ opacity: middleLeftVisibility, display: anesthesieDisplay }}>
                            <TouchableOpacity onPress={() => leftChoice(3, contextItem)}>
                                <Text style={[styles.boutonMiddleLeft, { opacity: opac3 }]}>Anesthésie</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ opacity: middleLeftVisibility, display: traitementDisplay }}>
                            <TouchableOpacity onPress={() => leftChoice(4, contextItem)} >
                                <Text style={[styles.boutonMiddleLeft, { opacity: opac4 }]}>Traitement</Text>
                            </TouchableOpacity>
                        </View>
                        {/* <View style={{ opacity: middleLeftCompVisibility }}> */}
                        <View style={[styles.complement, { opacity: middleLeftCompVisibility }]}>
                            <View style={[styles.demiBt, { opacity: subOpac1, display: subDisplay1 }]}>
                                <TouchableOpacity onPress={() => subLeftChoice(1, contextItem)} >
                                    <Text style={styles.demiBtText}>Acide Hyaluronique</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.demiBt, { opacity: subOpac2, display: subDisplay2 }]}>
                                <TouchableOpacity onPress={() => subLeftChoice(2, contextItem)} >
                                    <Text style={styles.demiBtText}>Fils résorbables</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={[styles.demiBt, { opacity: subOpac3, display: subDisplay3 }]}>
                                <TouchableOpacity onPress={() => subLeftChoice(3, contextItem)} >
                                    <Text style={styles.demiBtText}>Qualité de peau</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        {/* </View> */}
                    </View>
                    <View style={styles.ligneMiddleRight}>
                        <View style={{ opacity: middleLeftVisibility }}>
                            <TouchableOpacity onPress={() => leftChoice(5, contextItem)} >
                                <Text style={[styles.boutonMiddleLeft2, { opacity: opac5, display: photosDisplay }]}>Photos Pré et Post traitement</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ opacity: middleLeftVisibility }}>
                            <TouchableOpacity onPress={() => leftChoice(6, contextItem)}>
                                <Text style={[styles.boutonMiddleLeft2, { opacity: opac6, display: presentationDisplay }]}>Présentation médecin</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.arrowline, { opacity: arrowDisplay }]}>
                            <TouchableOpacity onPress={() => navigate2(contextItem)}>
                                <Ionicons name="arrow-forward-circle" size={60} color="#F2EDB0" style={styles.arrow} />
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </View>
            <TouchableOpacity style={styles.homeBt} onPress={() => navigation.navigate('body')}>
                <Ionicons name="home" size={32} color="white" style={styles.homeIcon} />
            </TouchableOpacity>
            <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
        </SafeAreaView>
    );

   

}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
        flexDirection: 'row',
        paddingTop: Constants.statusBarHeight + 5
    },
    titre: {
        color: '#ffffff',
        fontSize: 20,
        paddingTop: 8,
        paddingBottom: 8,
        textAlign: 'center',
        // backgroundColor: '#ff00ff'

    },
    btGauche: {
        fontSize: 14,
        color: '#ffffff',
        textAlign: "left",
        backgroundColor: '#8B0A9C',
        opacity: 0.5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 2,
        marginBottom: 2,
        marginRight: 10,
        marginLeft: 10
    },
    colLeft: {
        // backgroundColor: 'green',
        flex: 2,
    },
    colRight: {
        // backgroundColor: 'blue',
        flex: 5,
    },
    ligneMiddle: {
        // backgroundColor: "#00ff00",
        flex: 1,
        flexDirection: 'row'
    },
    ligneMiddleLeft: {
        flex: 1,
        // backgroundColor: '#7AD35D',
        flexDirection: 'column'
    },
    boutonMiddleLeft: {
        backgroundColor: '#41C6F8',
        margin: 8,
        textAlign: 'center',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#ffffff',
    },
    boutonMiddleLeft2: {
        backgroundColor: '#17CA0F',
        margin: 8,
        textAlign: 'center',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#ffffff',
    },
    complement: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 6
    },
    demiBt: {
        flex: 1
    },
    demiBtText: {
        fontSize: 16,
        color: '#ffffff',
        textAlign: "center",
        backgroundColor: '#F09108',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 2,
        marginBottom: 2,
        marginRight: 10,
        marginLeft: 10
    },
    ligneMiddleRight: {
        flex: 1,
        // backgroundColor: '#EE9E1C'
    },
    arrowLine: {
        backgroundColor: '#ff0000',
        flex: 1,
        height: 80,
        width: 200
    },
    mainLogo: {
        width: 1920 / 30,
        height: 1920 / 30,
        position: 'absolute',
        bottom: 10,
        right: 0
    },
    homeBt: {
        position: 'absolute',
        top: Constants.statusBarHeight + 8,
        right: 10
    },
    arrow: {
        textAlign: 'center',
        // paddingRight: 20,
        marginTop: 40
    }
});




//
