import React, { useEffect, useContext, useState } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, ImageBackground, Image, Dimensions  } from 'react-native';
import { SafeAreaView, } from 'react-native-safe-area-context';
// CUSTOM
import MenuBar from './MenuBar';
import { Mycontext } from "../context/Context";
// CONSTANT
const dimensions = Dimensions.get('window');
//
const originalHeight = 1080;
let newDimensionsHeight = 0;

let ratio = 0;
if(dimensions.height < dimensions.width){
    newDimensionsHeight = dimensions.height;
    ratio = newDimensionsHeight / originalHeight;
}else{
    newDimensionsHeight = dimensions.width;
    ratio = newDimensionsHeight / originalHeight;
}
//
const imageMap = {
    'fullHead': require('../../assets/tete-open-all.png'),
    'topHead': require('../../assets/tete-open-top.png'),
    'topHead1': require('../../assets/tete-open-top-1.png'),
    'topHead2': require('../../assets/tete-open-top-2.png'),
    'topHead3': require('../../assets/tete-open-top-3.png'),
    'topHead4': require('../../assets/tete-open-top-4.png'),
    'topHead5': require('../../assets/tete-open-top-5.png'),
    'middleHead': require('../../assets/tete-open-middle.png'),
    'middleHead1': require('../../assets/tete-open-middle-1.png'),
    'middleHead2': require('../../assets/tete-open-middle-2.png'),
    'middleHead3': require('../../assets/tete-open-middle-3.png'),
    'bottomHead': require('../../assets/tete-open-bottom.png'),
    'bottomHead1': require('../../assets/tete-open-bottom-1.png'),
    'bottomHead2': require('../../assets/tete-open-bottom-2.png'),
}
const elementHeight =[
    {id: 1, height: 500 , color: "red"},
    {id: 2, height: 274, color: "blue"},
    {id: 3, height: 306, color: "green"},
]
export default function HeadContent({navigation}){
    //
    const { datas, menuLeft, setMenuLeft, setCurrentItem, setCurrentSubItem, setContextItem } = useContext(Mycontext);
    const [currentImageMapping, setCurrentImageMapping] = useState('fullHead');
    //
    useEffect(() => {
        setCurrentItem(null);
        setCurrentSubItem(null);
        setMenuLeft(null);

    }, []);
    

    //
    const clickPart = (item) => {
        // setContextItem(item);
        setMenuLeft(item.zones);
        switch(item.route){
            case 'tete-haute':
                setCurrentImageMapping('topHead');
                break;
            case 'tete-milieu':
                setCurrentImageMapping('middleHead');
                break;
            case 'tete-bas':
                setCurrentImageMapping('bottomHead');
                break;
        }
    }
    //
    const subSelect = (item) => {
        setCurrentSubItem(item.id);
        setCurrentItem(item.route);
        switch (item.route){
            case 'cuir-chevelu':
                setCurrentImageMapping('topHead1');
                break;
            case 'queue-sourcil':
                setCurrentImageMapping('topHead2');
                break;
            case 'cernes':
                setCurrentImageMapping('topHead3');
                break;
            case 'front':
                setCurrentImageMapping('topHead4');
                break;
            case 'tempes':
                setCurrentImageMapping('topHead5');
                break;
            case 'nez':
                setCurrentImageMapping('middleHead1');
                break;
            case 'pomette':
                setCurrentImageMapping('middleHead2');
                break;
            case 'levres':
                setCurrentImageMapping('middleHead3');
                break;
            case 'menton':
                setCurrentImageMapping('bottomHead1');
                break;
            case 'cou':
                setCurrentImageMapping('bottomHead2');
                break;
        }
        setContextItem(item);
        setTimeout(() => navigation.navigate('docteurs'), 2000);

    }
    //
    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.colonne1}>
                <View style={ styles.subcol1 }>
                    <FlatList data={menuLeft} keyExtractor={(item, index) => 'keyOne' + index} renderItem={({ item }) => {
                    return (
                        <TouchableOpacity item={item} onPress={() => subSelect(item)}>
                            <Text style={styles.btGauche}>{item.titre}</Text>
                        </TouchableOpacity>
                    );
                }} />
                </View>
                <View style={styles.subcol2}>
                    <TouchableOpacity onPress = { ()=> navigation.navigate('rappel') }>
                        <Image source={imageMap['fullHead']} style={styles.btHead} resizeMode='cover'></Image>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={styles.colonne2}>
                <ImageBackground source={imageMap[currentImageMapping] } style={[styles.containerBody, {height: originalHeight * ratio}]}>
                    <FlatList data={datas['bodyParts'][0]['bodySubParts']} keyExtractor={( item, index) => 'keyTwo' + index} renderItem={({ index, item }) => {
                        return (
                            // <HeadTouchableOpacity item={item} route={item.route} currentItem={currentItem} currentSubItem={currentSubItem} onclickParent={clickPart} />
                            <TouchableOpacity onPress={() => clickPart(item)} style={{ height: elementHeight[index].height * ratio}}></TouchableOpacity>
                        )
                    }} />
                </ImageBackground>
            </View>
            <MenuBar style={styles.homeBt} navigation={navigation}/>
            <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
        </SafeAreaView>
    )
}
const styles = StyleSheet.create({
    containerBody: {
        flex: 1
    },
    btGauche: {
        fontSize: 16,
        color: 'white',
        textAlign: "left",
        backgroundColor: 'rgba(255,255,255,0.2)',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 2,
        marginBottom: 2,
        marginRight:20,
        marginLeft: 10
    },
    titre: {
        fontSize: 30,
        color: 'white',
        textAlign: "center"
    },
    colonne1: {
        backgroundColor: "#000000",
        flex: 1
    },
    subcol1:{
        // backgroundColor: "#000000",
        height: newDimensionsHeight - 100,
    },
    subcol2:{
        // backgroundColor: "#000000",
        height: 100
    },
    colonne2: {
        // backgroundColor: "#000000",
        flex: 1
    },
    container: {
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: '#000000',
        flexDirection: "row",
        // paddingTop: Constants.statusBarHeight + 5,
    },
    mainLogo: {
        width: 1920 / 30,
        height: 1920 / 30,
        position: 'absolute',
        bottom: 10,
        right: 0
    },
    homeBt: {
        position: 'absolute',
    },
    btHead:{
        height:60,
        width:60,
        marginLeft:20
    }
})