import React, { useEffect, useContext, useState } from 'react';
import { View, Text, FlatList, StyleSheet, TouchableOpacity, ImageBackground, Image, Dimensions } from 'react-native';
import MenuBar from './MenuBar';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Mycontext } from "../context/Context";
// CONSTANT
const dimensions = Dimensions.get('window');
let newDimensionsHeight = 0;
if (dimensions.height < dimensions.width) {
    newDimensionsHeight = dimensions.height;
} else {
    newDimensionsHeight = dimensions.width;
}
// IMAGES
var imageMap = {
    'fullLegs': require('../../assets/legs-open-all.png'),
    'fullLegs1': require('../../assets/legs-open-all-1.png'),
    'fullLegs2': require('../../assets/legs-open-all-2.png'),
    'fullLegs3': require('../../assets/legs-open-all-3.png'),
}

export default function LegsComponent({navigation}){
    const { datas, menuLeft, setMenuLeft, setContextItem, setCurrentItem, setCurrentSubItem } = useContext(Mycontext);
    const [currentImageMapping, setCurrentImageMapping] = useState('fullLegs');
    //
    useEffect(() => {
        setMenuLeft(datas['bodyParts'][2].bodySubParts[0].zones);
    }, []);
    //
    const subSelect = (item, titre) => {
        setCurrentSubItem(item.id);
        setCurrentItem(item.route);
        switch (item.route) {
            case 'fesses':
                setCurrentImageMapping('fullLegs1');
                break;
            case 'cuisses':
                setCurrentImageMapping('fullLegs2');
                break;
            case 'genoux':
                setCurrentImageMapping('fullLegs3');
                break;
            default:
                setCurrentImageMapping('fullLegs');
        }
        setContextItem(item);
        setTimeout(() => navigation.navigate('docteurs'), 2000);
    }
    //
    return (
        <SafeAreaView style={styles.container}>
            <View style={styles.colonne1}>
                <View style={styles.subcol1}>
                    <FlatList data={ menuLeft} keyExtractor={(item, index) => 'key' + index} renderItem={({ item }) => {
                        return (
                            <TouchableOpacity item={item} onPress={() => subSelect(item, item.titre)}>
                                <Text style={styles.btGauche}>{item.titre}</Text>
                            </TouchableOpacity>
                        );
                    }} />
                </View>
            </View>
            <View style={styles.colonne2}>
                <ImageBackground source={imageMap[currentImageMapping]} style={styles.containerBody}>
                </ImageBackground>
            </View>
            <MenuBar style={styles.homeBt} navigation={navigation}/>
            <Image source={require('../../assets/adaptive-icon-white.png')} style={styles.mainLogo}></Image>
        </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    containerBody: {
        flex: 1
    },
    btGauche: {
        fontSize: 16,
        color: 'white',
        textAlign: "left",
        backgroundColor: 'rgba(255,255,255,0.2)',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 2,
        marginBottom: 2,
        marginRight: 20,
        marginLeft: 10
    },
    titre: {
        fontSize: 30,
        color: 'white',
        textAlign: "center"
    },
    colonne1: {
        backgroundColor: "#000000",
        flex: 1
    },
    subcol1: {
        // backgroundColor: "#000000",
        height: newDimensionsHeight - 100,
    },
    subcol2: {
        // backgroundColor: "#000000",
        height: 100
    },
    colonne2: {
        // backgroundColor: "#000000",
        flex: 1
    },
    container: {
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        backgroundColor: '#000000',
        flexDirection: "row",
        // paddingTop: Constants.statusBarHeight + 5,
    },
    mainLogo: {
        width: 1920 / 30,
        height: 1920 / 30,
        position: 'absolute',
        bottom: 10,
        right: 0
    },
    homeBt: {
        position: 'absolute',
    },
    btHead: {
        height: 60,
        width: 60,
        marginLeft: 20
    }
})