import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
//
import LoadScreen from '../components/LoadScreen';
import ScreenBody from '../components/ScreenBody';
import HeadContent from '../components/HeadContent';
import ChestComponent from '../components/ChestComponent';
import LegsComponent from '../components/LegsComponent';
import DocteurScreen from '../components/DocteurScreen';
import RappelAnatomique from '../components/RappelAnatomique';
import ScreenVideo from '../components/ScreenVideo';
import MediaList from '../components/MediaList';
import ScreenPresentationDoctor from '../components/ScreenPresentationDoctor';
//
const Stack = createStackNavigator();
//
export default function MainStackNavigator() {
    return (
        <NavigationContainer>
            <Stack.Navigator initialRouteName='load-screen' screenOptions={{ gestureEnabled: true }}>
                <Stack.Screen name='load-screen' component={LoadScreen} options={{ headerShown: false }} />
                <Stack.Screen name='body' component={ScreenBody} options={{ headerShown: false }} />
                <Stack.Screen name='tete' component={HeadContent} options={{ headerShown: false }} />
                <Stack.Screen name='tronc' component={ChestComponent} options={{ headerShown: false }} />
                <Stack.Screen name='jambes' component={LegsComponent} options={{ headerShown: false }} />
                <Stack.Screen name='docteurs' component={DocteurScreen} options={{ headerShown: false }} />
                <Stack.Screen name='rappel' component={RappelAnatomique} options={{ headerShown: false }} />
                <Stack.Screen name='video' component={ScreenVideo} options={{ headerShown: false }} />
                <Stack.Screen name='medias-list' component={MediaList} options={{ headerShown: false }} />
                <Stack.Screen name='presentation' component={ScreenPresentationDoctor} options={{ headerShown: false }} />
            </Stack.Navigator>
        </NavigationContainer>
    )
}